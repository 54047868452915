<template>
  <div class="cooperate">
    <!--合作头部-->
    <DetailsHeader />
    <main>
      <div class="box">
        <div class="title">商务合作</div>
        <p class="text">
          园区真景三维拍摄或者其他想合作的个人及企业，都可以与我们联系。
        </p>
        <div class="content">
          <label for="">您的称呼</label>
          <el-input class="inp" v-model="name" placeholder="请输入"></el-input>
        </div>
        <div class="content">
          <label for="">联系方式</label>
          <el-input
            class="inp"
            v-model="contact"
            placeholder="请输入"
          ></el-input>
        </div>
        <div class="content">
          <label for="">详细说明</label>
          <el-input
            class="inp"
            type="textarea"
            :rows="2"
            v-model="content"
            placeholder="示例：我有园区想要入驻、3D拍摄或政府企业合作相关。"
          ></el-input>
        </div>

        <div class="content">
          <el-button class="btn" type="primary" @click="submit">提交</el-button>
        </div>
      </div>
    </main>
    <Footer />
  </div>
</template>
  
  <script>
import DetailsHeader from 'components/office/DetailsHeader.vue';
import Footer from 'components/home/footer.vue';
import { cooperation } from 'services/member'
export default {
  name: 'Cooperate',
  components: {
    DetailsHeader,
    Footer
  },
  data () {
    return {
      name: '',
      contact: '',
      content: '',
    };
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    submit () {
      if (!this.contact) {
        return this.$message.warning('请输入联系方式')
      } else {
        let params = {
          name: this.name,
          contact: this.contact,
          content: this.content,
        }
        cooperation(params).then(res => {
          if (res.data.status === 200) {
            this.name = ''
            this.contact = ''
            this.content = ''
            return this.$message({
              type: 'success',
              message: res.data.msg
            })

          }
        })
      }
    }
  }
};
  </script>
  <style lang="less" scoped>
.cooperate {
  main {
    height: 1590px;
    background: url(../assets/cooperate.png);
    position: relative;
    .box {
      position: absolute;
      top: 30px;
      right: 336px;
      width: 509px;
      height: 397px;
      opacity: 1;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.52);
      border: 1px solid rgba(255, 255, 255, 1);
      padding: 12px 70px;
      .title {
        font-size: 24px;
        font-weight: 400;
        line-height: 34.75px;
        color: rgba(51, 51, 51, 1);
        margin-bottom: 12px;
        text-align: center;
      }
      p.text {
        font-size: 16px;
        font-weight: 400;
        line-height: 23.17px;
        color: rgba(102, 102, 102, 1);
        margin-bottom: 16px;
      }
      .content {
        display: flex;
        align-items: center;
        margin-bottom: 21px;
        label {
          width: 64px;
          font-size: 16px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          margin-right: 16px;
        }
        .inp {
          width: calc(100% - 80px);
          /deep/ .el-input__inner {
            height: 36px;
            opacity: 1;
            border-radius: 3px;
            background: rgba(255, 255, 255, 0.38);
            border: 1px solid rgba(255, 255, 255, 1);
            &::placeholder {
              color: #999;
            }
          }
          /deep/ .el-textarea__inner {
            height: 82px;
            opacity: 1;
            border-radius: 3px;
            background: rgba(255, 255, 255, 0.38);
            border: 1px solid rgba(255, 255, 255, 1);
            &::placeholder {
              color: #999;
            }
          }
        }
        .btn {
          margin-left: 80px;
          width: calc(100% - 80px);
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
}
</style>
  